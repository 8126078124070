/**
 * 退会フローのStoreコントロール用middleware
 */
import { useUserState } from '@/composables/store/useUserStore';

export default defineNuxtRouteMiddleware((to, from) => {
  const { setUnsubscribeReason } = useUserState();

  // 退会フロー以外に遷移した場合は退会理由の選択をリセット
  if (to.meta.flow !== 'unsubscribe') setUnsubscribeReason(undefined);
});
